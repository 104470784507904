import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import { fetch, post } from '@/utils/Request'
import i18n from './i18n'

Vue.use(ElementUI)
// Vue.prototype.$center_url = "http://192.168.1.30:7006"
Vue.prototype.$center_url = "https://www.stareast.io/api"
Vue.prototype.$ipfs_url = "https://nft-store.frp.isecsp.com/ipfs/"
Vue.prototype.$fetch = fetch
Vue.prototype.$post = post

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
