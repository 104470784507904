/*
 * Requests.js 
 * 用于常规请求操作
 * author:shuijunzi
 */

import axios from "axios"
axios.defaults.timeout = 10000;

/**
 * GET请求
 * @param {string} url 请求地址
 * @param {object} params 请求参数
 */
export function fetch(url,params={}){
    return new Promise((resolve,reject) => {
        axios.get(url, {params:params}).then(response => {
            resolve(response.data)
        }).catch(error => {
            this.$toast(error.response.data.error)
            reject(error)
        })
    })
}

/**
 * POST请求
 * @param {string} url 请求地址
 * @param {object} params 请求参数
 */
export function post(url, params={}){
    return new Promise((resolve,reject) => {
        axios.post(url,params).then((response) => {
            console.log(response)
            resolve(response.data)
        }).catch(error => {
            console.log(error)
            this.$toast(error.response.data.error)
            reject(error)
        })
    })
}