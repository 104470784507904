import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home/index.vue')
  },
  {
    path: '/CreateNFT',
    name: 'CreateNFT',
    component: () => import(/* webpackChunkName: "CreateNFT" */ '../views/CreateNFT/index.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login/index.vue')
  },
  {
    path: '/Message',
    name: 'Message',
    component: () => import(/* webpackChunkName: "Message" */ '../views/Message/index.vue')
  },
  {
    path: '/Subscribe',
    name: 'Subscribe',
    component: () => import(/* webpackChunkName: "Subscribe" */ '../views/Subscribe/index.vue')
  },
  {
    path: '/MovieDetail',
    name: 'MovieDetail',
    component: () => import(/* webpackChunkName: "MovieDetail" */ '../views/MovieDetail/index.vue')
  },
  {
    path: '/About',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '../views/About/index.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: "/",
  routes
})

export default router
