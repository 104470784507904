<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data(){
    return{

    }
  },
  methods:{
   
  },
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body,div,span,a,div,ul,li,input,button,p,textarea{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
li {
  list-style: none;
}
.ellipsis {
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}
.outer_box{
    width: 1124px;
    margin: 0 auto;
}
.content_box{
  min-height: 86vh;
}
.movie_list{
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    &_item{
        cursor: pointer;
        flex-basis: 16%;
        margin-bottom: 15px;
        &_box{
            margin-right: 6px;
            .img_box{
                margin: 0 auto;
                width: 160px;
                height: 210px;
            }
            .movie_name{
                width: 180px;
                margin: 4px auto;
                font-size: 15px;
            }
            .movie_desc{
                font-size: 12px;
                color: #CCCCCC;
                width: 180px;
            }
        }
    }
}
.pg_header{
    margin: 20px 0;
}
</style>
